<template>
     <div class=" industry body">
          <div class="container headcarousel">
               <div>
                    <div class="columns reverse-columns">
                         <div class="column ">
                              <div class="image" data-aos="fade-right" data-aos-duration="1000">
                                   <!-- <img :src="require(`@/assets/images/industries/healthcare.jpg`)" alt="" /> -->

                                   <div
                                        class="square img"
                                        :style="{ 'background-image': `url(${require('@/assets/images/industries/healthcare.jpg')})` }"
                                   ></div>
                              </div>
                         </div>
                         <div class="column">
                              <div class="text" data-aos="fade-left" data-aos-duration="1000">
                                   <h1>Healthcare <span class="uppertext">01</span></h1>

                                   <div class="mt-30">
                                        <p>CTL has contributed immensely to the technological transformation in the Healthcare sector.</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class="projects">
               <div class="container">
                    <div class="  section-title">Healthcare Projects</div>

                    <div class="columns" style="margin-bottom:50px;">
                         <div class="column">
                              <div class="section-header" style="margin-bottom:0;">
                                   Technological interventions to improve health & care services
                              </div>
                         </div>

                         <div class=" hoopernavigation">
                              <button class="prev" @click="hooperPrev()" :class="{ visible: hooper.isPrevBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(1115.74 145.803) rotate(180)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                              <button class="next" @click="hooperNext()" :class="{ visible: hooper.isNextBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(-1073 -126.299)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                         </div>
                    </div>
               </div>
               <div class="  overflow-carousel-container">
                    <div class="overflow-carousel">
                         <hooper :settings="hooper_settings" class="slider" style="" ref="hooper" @slide="hooperSlide">
                              <slide v-for="(item, index) in projects" :key="index" class="">
                                   <div class="slide-container">
                                        <div class="image-container">
                                             <!-- <img :src="require(`@/assets/images/${item.image}`)" /> -->

                                             <div
                                                  class="rect img"
                                                  v-if="item.image"
                                                  :style="{ 'background-image': `url(${require('@/assets/images/projects/' + item.image)})` }"
                                             ></div>

                                             <div
                                                  class="rect img"
                                                  v-else
                                                  :style="{ 'background-image': `url(${require('@/assets/images/placeholder.png')})` }"
                                             ></div>
                                        </div>
                                        <div class="mt-30 line-left  " style="padding-left:20px;min-height:91px;">
                                             <div class="date">{{ item.location }}</div>
                                             <p class="mt-10 large " v-html="item.name"></p>
                                        </div>
                                   </div>
                              </slide>
                         </hooper>
                    </div>
               </div>
          </div>

          <div class="content">
               <div class="container">
                    <div class="  section-title">Healthcare Services</div>
                    <div class="  section-header">Enabling Care Systems to meet modern challenges & threats.</div>

                    <div class="columns">
                         <div class="column ">
                              <div class="line-top pt-6 mt-6 ">
                                   <!-- <div class="section-title ">
                                        Benefits of ITeS System
                                   </div> -->
                                   <p>
                                        The healthcare industry has seen tremendous technological integrations in recent years.With the addition of
                                        more and more connected medical devices and other IT tools to their digital environments, reliable networks
                                        are essential to allow providers to process information quickly and efficiently, so they can spend more time
                                        interacting with patients. Hence, network connectivity is a critical piece of IT infrastructure in Healthcare.
                                   </p>

                                   <div class="line-top   pt-6 mt-6 ">
                                        <div class="section-title ">
                                             The Internet of Medical Things (IoMT)
                                        </div>
                                        <p>
                                             The IoMT is a connected infrastructure of medical devices, software applications, and health systems and
                                             services. The capabilities of IoMT are more accurate diagnoses, minimised errors and optimised costs of
                                             care. Paired with smartphone applications, the technology allows real time exchange of patient
                                             information so doctors can monitor, track and prevent chronic illnesses.
                                        </p>
                                   </div>

                                   <div class="line-top  pt-6 mt-6  ">
                                        <div class="section-title ">
                                             Advanced PACS Systems
                                        </div>
                                        <p>
                                             3-D modalities, such as CT and MR, are creating ever-larger data volumes, increasing the need for faster,
                                             bigger servers, higher network bandwidth, workstations with large memory and fast graphics, and advanced
                                             diagnostic software. Furthermore, the gap between the amount of information in the original data and 2-D
                                             report images sent to clinicians and referring physicians is growing steadily. Radiology departments and
                                             3-D technologists expend significant effort to generate multiple series of reformatted images, cine
                                             loops, and standardised 3-D views to document and communicate diagnostic findings. Still, physicians
                                             outside the radiology department often want the flexibility to review the original data in addition to
                                             the diagnostic report.
                                        </p>
                                   </div>

                                   <div class="line-top   pt-6 mt-6 ">
                                        <p>
                                             CTL understands the healthcare connectivity requirement and we always design the network considering the
                                             future technology upgrades, reliability redundancy and flexibility .
                                        </p>

                                        <p>
                                             The number of connected medical devices continues to grow, and the network will always be the common
                                             denominator that supports all digital communication activity. Without digging deep and building a network
                                             that can keep up with the constant and urgent connections, organisations risk unreliable systems that
                                             have significant negative downstream impacts.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div class="column ">
                              <div class="line-top  pt-6 mt-6 ">
                                   <div class="section-title ">
                                        Specific Services
                                   </div>
                                   <p>
                                        CTL has over 25 years of experience in providing solutions for the hospitality industry. We provided the
                                        following well designed solutions based on the project requirement.
                                   </p>

                                   <p>
                                        Wired and Wireless Data <br />
                                        Switching and Routing <br />
                                        Firewalls and Servers <br />
                                        IP Telephony <br />
                                        IP Surveillance <br />
                                        Access control systems <br />
                                        Building Management Systems <br />
                                        Data Centre and Command control solutions <br />
                                        IoT and Room Management Systems <br />
                                        Audio and Video <br />
                                        Digital Signage <br />
                                        IP TV Solutions <br />
                                        Parking Management <br />
                                        Addressable smoke detection systems <br />
                                        DAS (Distributed Antenna Systems) <br />
                                   </p>
                              </div>
                              <div class="line-top  pt-6 mt-6  ">
                                   <div class="section-title ">
                                        The Robotic technology
                                   </div>
                                   <p>
                                        With the benefit of nearly two decades of innovation, including advancements in robotics and the speed of
                                        network connectivity, the concept of tele-treatment is very imminent. If implemented wisely, it could
                                        democratise high-level care for patients worldwide. The complex logistics of remote procedures, combined with
                                        concerns about Internet reliability, bandwidth, speed, and security have all prevented widespread
                                        implementation of tele-robotic surgeries to date.
                                   </p>

                                   <div class="para-large mt-60">
                                        View Projects that have availed CTLs Solution in Healthcare.
                                        <br />
                                        <router-link :to="{ name: 'PROJECTS' }" class="knowmore">Know More</router-link>
                                   </div>
                              </div>

                              <div class="line-top  pt-6 mt-6  ">
                                   <p>
                                        Healthcare wireless networks are complex and will always challenge organisations, no matter how advanced they
                                        are. Investing in the network may appear to be a daunting expense, but less downtime and better connectivity
                                        is invaluable to modern healthcare organisations.
                                   </p>

                                   <p>
                                        CTL does not look at Healthcare as another IT project, we are committed to make the lives of each patient
                                        better. We want to ensure efficient Patient care by making sure that our designed networking, WiFi, and
                                        infrastructure is 100 percent reliable.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { Hooper, Slide } from "hooper";
     import "hooper/dist/hooper.css";

     export default {
          data() {
               return {
                    hooper: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },
                    hooper_settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,
                         breakpoints: {
                              800: {
                                   itemsToShow: 1,
                                   itemsToSlide: 1,
                              },
                              1000: {
                                   itemsToShow: 3.6,
                                   itemsToSlide: 1,
                              },
                         },
                    },

                    sliderOptions: {},
                    selected: 0,

                    projects: [
                         {
                              name: "Aster Medcity",
                              location: "Kerala",
                              text: "Data Centre Build <br> Fire Alarm Public Voice <br> Evacuation System",
                              image: "healthcare/dm/1.jpg",
                         },

                         {
                              name: "MVR Cancer Center",
                              location: "Kerala",
                              text: `IT Server & Solution <br> Data & Voice ICT Solution <br> Public Address System <br> Data Centre Build <br> Structured Cabling <br> Building Management Solution <br> Distributed Antenna system`,
                              image: "healthcare/mvr cancer centre/1.jpg",
                         },

                         {
                              name: "VPS Lakeshore Hospitals",
                              location: "Kerala",
                              text: "Active Switching <br> Data Centre Solutions",
                              image: "healthcare/lakeshore hospital/1.jpg",
                         },
                         {
                              name: "Kasargod Medical College ",
                              location: "Kerala",
                              text: "ELV Works <br> ICT Active Systems",
                              image: "healthcare/kasargod medical college/1.jpg",
                         },
                         {
                              name: "Rajagiri Hospital",
                              location: "Kerala",
                              text: "Data Centre Build <br> Structured Cabling <br> Life Safety Electronic Equipments",
                              image: "healthcare/rajagiri hospital/1.jpg",
                         },
                         {
                              name: "MVR Palliative Care",
                              location: "Kerala",
                              text: "Disaster Recovery Build <br> Data & Voice ICT Systems <br> IT Surveillance Solution",
                              image: "healthcare/mvr palliative/1.jpg",
                         },

                         {
                              name: "Welcare Hospital",
                              location: "Kerala",
                              text: "Data Centre Build",
                              image: "healthcare/Welcare hostital/1.jpg",
                         },

                         
                    ],
               };
          },
          components: {
               Hooper,
               Slide,
          },
          methods: {
               hooperSlide(data) {
                    this.hooper.slideNo = data.currentSlide;

                    if (this.hooper.slideNo > 0) {
                         this.hooper.isPrevBtnVisible = true;
                    } else {
                         this.hooper.isPrevBtnVisible = false;
                    }

                    if (window.innerWidth < 1000) {
                         if (this.hooper.slideNo == this.projects.length - 1) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    } else {
                         if (this.hooper.slideNo > 3.3) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    }
               },
               hooperPrev() {
                    this.$refs.hooper.slidePrev();
               },
               hooperNext() {
                    this.$refs.hooper.slideNext();
               },
          },

          mounted() {},
     };
</script>

<style lang="scss" scoped>
     .section-header {
          margin-bottom: 10px;
     }

 
</style>
